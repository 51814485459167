import {useEffect, useRef} from 'react';
import anime from 'animejs';

function Navbar(){
    const nav_logo = useRef(null);
    const nav_items = useRef(null);
    const animate = () => {
        anime.timeline({loop: false}).add({
            targets: nav_logo.current.querySelector('.logo'),
            translateY: [100, 0],
            duration: 1000,
            easing: 'easeOutExpo'
        });
        anime.timeline({loop:false}).add({
            targets: nav_items.current.querySelectorAll('.nav-item h4'),
            translateY: [100, 0],
            duration: 1000,
            easing: 'easeOutExpo',
            delay: (el, i) => 100 * i
        });
    };

    useEffect(() => {
        animate();
    }, []);
    return(
        <div className={"navbar grid"}>
            <div className="item end-11 inline-flex center">
                <div ref={nav_logo} className="hide-overflow inline-flex" style={{padding: '2rem'}}>
                    <a href="/" className={"logo flex center row"}>
                        <img src="./img/logo.svg"/>
                        <h3 style={{opacity: '1'}}>nebulanodes</h3>
                    </a>
                </div>

                
                    <div className={"flex row center max-height max-width align-right"}>
                        <div ref={nav_items} style={{position: 'relative'}} className={"flex row align-right"}>

                            <a href="#mission" className="nav-item flex col">
                                <h4>Our Mission</h4>
                                <h4 className="nav-item-hover">Our Mission</h4>
                            </a>

                            <a href="#promise" className="nav-item">
                                <h4>Our Promise</h4>
                                <h4 className="nav-item-hover">Our Promise</h4>
                            </a>

                            <a href="#contact" className="nav-item">
                                <h4>Contact us</h4>
                                <h4 className="nav-item-hover">Contact Us</h4>
                            </a>
                        </div>
                    </div>
                </div>
            

        </div>
    );
}

export default Navbar;