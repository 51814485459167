import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import anime from 'animejs';
import {useEffect, useRef} from 'react';

function Mission(){
    const ref = useRef(null);
    var star = <svg className="morph" width="194" height="224" viewBox="0 0 194 224" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M96.7501 1.83026L117.539 75.0166L117.672 75.4834L118.142 75.3648L191.925 56.7749L138.931 111.372L138.593 111.72L138.931 112.068L191.925 166.665L118.142 148.075L117.672 147.957L117.539 148.423L96.7501 221.619L75.971 148.423L75.8385 147.957L75.3678 148.075L1.58489 166.665L54.5788 112.068L54.9168 111.72L54.5788 111.372L1.58489 56.7749L75.3678 75.3648L75.8384 75.4834L75.971 75.0166L96.7501 1.83026Z" stroke="#695EFF"/>
    </svg>

    var octagon =<svg className="morph"  width="183" height="183" viewBox="0 0 183 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.63 27.5053L91.8162 1.43119L155.645 28.3801L168.687 60.4683L181.729 92.5564L168.259 124.466L154.79 156.375L90.6036 182.449L26.7751 155.5L13.7331 123.412L0.691094 91.3236L14.1605 59.4145L27.63 27.5053Z" stroke="#695EFF"/>
    </svg>;

    var diamond = <svg className= "morph"  width="199" height="198" viewBox="0 0 199 198" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.6935 123.226L25.6934 123.226L1.3771 98.9199L25.6935 74.6035L25.3399 74.25L25.6935 74.6035L75.0235 25.2635L99.3399 0.947097L123.656 25.2635L172.996 74.6035L197.313 98.9199L172.996 123.226L173.35 123.58L172.996 123.226L123.656 172.566L99.3399 196.883L75.0235 172.566L25.6935 123.226Z" stroke="#695EFF"/>
    </svg>;

    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current.querySelectorAll('.morph path'),
            strokeDashoffset: [anime.setDashoffset, 0 ],
            easing: 'easeOutExpo',
            duration: 2800
        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);
    return(
        <div id="mission" className="container-2">
            <div className="item end-11">
                <SmallHeader text={"our mission"}/>
            </div>

            <div className="start-5 row-5 end-11">
                <div className="flex row">
                <H2 text={"Engineering fast staking machines to shape your wallet tomorrow"}/>
                </div>
            </div>

            <div className="item row-15 end-11">
                <div className="flex row center">

                    <div className={"flex center"} ref={ref}>
                        {star}
                        {octagon}
                        {diamond}
                    </div>

                    <div>
                        <H3 text={"Remarkable growth is achieved by investing in the best opportunities"}/>
                        <p className="margin-top">
                            Striving to offer cutting-edge staking solutions that are simple, reliable, and lucrative. We believe in empowering individuals to take control of their financial future
                            Because when missteps cost expensive there’s no room for error.
                        </p>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Mission;