import {H1, H3} from './headers.js';
import {Button} from './buttons';

function Footer(){
    return(
        <div className={"footer"}>
            <div className="item end-11 ">
                <div className="line"></div>
                <div className={"flex row margin-top center"}>
                    <img id="footer-logo" src="/img/medium_logo.svg"/>
                    <div className="align-right">
                        <h2 style={{color: "var(--base)"}}>nebulanodes</h2>
                        <p>Cultivating wealth through cryptocurrency staking</p>
                        <div className="flex row">
                            <Button target="_blank" href="https://twitter.com/NebulaNodes" icon={"/img/twitter_logo.svg"}/>
                            <Button target="_blank" href="https://matrix.to/#/@nebulanodes:matrix.org" icon={"/img/element_logo.svg"}/>
                        </div>
                        <p >support@nebulanodes.io</p>
                    </div>
                </div>
                <div className="line margin-top"></div>
            </div>
        </div>
    );
};

export default Footer;