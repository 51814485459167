import {H2, H1, H4} from '../components/headers';
import {Button} from '../components/buttons';
import {useRef, useEffect} from 'react';
import anime from 'animejs';

function Landing(){
    const svg = <svg width="1051" height="1052" viewBox="0 0 1051 1052" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M879.286 702.346V702.308L877.917 703.689C875.271 706.358 871.654 707.849 867.893 707.849H579.374C565.321 707.849 558.268 724.849 568.216 734.796L697.497 864.078C703.033 869.637 703.031 878.625 697.499 884.157L535.565 1046.09C530.006 1051.63 521.018 1051.62 515.486 1046.09L347.356 877.962L347.353 877.959C344.684 875.313 343.193 871.696 343.193 867.935V691.011V579.391C343.193 565.338 326.193 558.285 316.246 568.233L186.964 697.514C181.405 703.05 172.417 703.048 166.885 697.516L4.95126 535.582C-0.584418 530.023 -0.582688 521.035 4.95001 515.503L173.08 347.398L173.083 347.395C175.729 344.726 179.346 343.235 183.107 343.235H360.031H471.651C485.704 343.235 492.757 326.235 482.809 316.288L353.527 187.006C347.992 181.447 347.994 172.459 353.526 166.926L515.46 4.99325C521.019 -0.542426 530.006 -0.540696 535.539 4.99201L703.644 173.122L703.647 173.125C706.316 175.771 707.807 179.387 707.807 183.149V360.073V471.693C707.807 485.746 724.807 492.798 734.754 482.851L864.036 353.569C869.595 348.034 878.583 348.036 884.115 353.568L1046.05 515.502C1051.58 521.061 1051.58 530.048 1046.05 535.581L879.286 702.346ZM370.082 514.371L370.081 514.373C363.925 520.555 363.923 530.554 370.082 536.713L514.354 680.985L514.356 680.986C520.538 687.142 530.537 687.144 536.696 680.985L680.968 536.713L680.969 536.711C687.125 530.529 687.126 520.53 680.968 514.371L536.696 370.099L536.694 370.098C530.512 363.942 520.513 363.94 514.354 370.099L370.082 514.371Z" stroke="#322C81" stroke-width="1.6"/>
    </svg>;
    
    const ref = useRef(null);
    const box_ref = useRef(null);

    const animate = () => {
        anime.timeline().add({
            targets: box_ref.current,
            width: ['0%', '100%'],
            easing:'easeOutExpo',
            duration: 1400, 
        });

        anime.timeline({loop:true}).add({
            targets: ref.current.querySelectorAll('.project'),
            
        })
    }

    useEffect(() => {
       animate();
    }, []);
    return(
        <div className="container">
            <div className="start-6">
                <div ref={ref} className="background-logo">{svg}</div>
            </div>

            <div className={"item row-10 end-11"}>
                <div className="flex row item">
                    <H2 text={"Cultivating digital wealth through cryptocurrency staking"}/>
                    <div className="align-bottom align-right">
                        <Button href="#mission" text={"learn more"}/>
                    </div>
                </div>
                <div className="margin-top">
                    <H4 text={"testnetting on"}/>
                </div>

                <div ref={box_ref} className="border-container flex center hide-overflow" style={{marginTop: '0.5rem'}} >                
                    
                    <div ref={ref} className="flex row center">
                        <div className="project flex row center">
                            <H1 text={'Artela'}/>
                        </div>

                        <div className="project flex row center">
                            <H1 text={'Avalanche'}/>
                        </div>

                        <div className="project flex row center">
                            <H1 text={'Band'}/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Landing;