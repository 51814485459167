import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import Card from '../components/card';


function Promise(){
    return(
        <div id="promise" className="container">

            <div className="item end-11">
                <SmallHeader text={"our promise"}/>
            </div>

            <div className="item row-3 end-10">
                <H2 text={"With the sharpest tools in our arsenal..."}/>
                <H3 text={"Let us drive outcomes for you"}/>
            </div>

            <div className="flex row item row-10 end-11">
                <Card title={"Reliable"} src={1} text={"Rigorous protocols and state-of-the-art security measures to guarantee the integrity of our nodes."} delay={1}/>
                <Card title={"Fast"} src={2} text={"Trust that your transactions will be among the fastest in the network, providing you with a competitive edge in the fast-paced world of digital finance."} delay={2}/>
                <Card title={"Profitable"} src={3} text={"We maximize gains with not one dollar wasted, A team focused on growth, we unite with your vision."} delay={4}/>
            </div>

        </div>
    );
}

export default Promise;