import React from 'react';
import logo from './logo.svg';
import './App.css';
import Landing from './pages/Landing';
import Mission from './pages/mission';
import Promise from './pages/promise';
import Contact from './pages/contact';
import Navbar from './components/navbar';
import Footer from './components/footer';

function App() {
  return (
    <div className="wrapper">
      <Navbar/>
      <Landing/>
      <Mission/>
      <Promise/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
